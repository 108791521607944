import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Page = () => {
  const breadPaths = [
    {
      text: "component list",
    },
  ]

  return (
    <Layout breadPaths={breadPaths}>

      <SEO title="Home" />
      
      {/* 1st block */}

      <section className="flex flex-col-reverse lg:flex-row w-full relative over:-mx-1col1gap over:w-14col13gap">

        <div className="flex flex-col md:flex-row lg:flex-col lg:w-3col2gap over:w-4col3gap lg:flex-grow-0 over:pl-1col1gap">

          <div className="hidden lg:flex md:w-3col2gap flex-shrink-0 flex-row items-center">
            <div className="hidden xl:block relative mb-1gap md:mb-2gap mx-auto w-2/3 max-w-3col xl:w-full">
              <img className="block rounded-full border-black border-1gap" src="/imgs/yuko-profile.jpg" alt="" />
              <span className="hidden lg:block absolute w-screen border-black lg:border-t-1gap right-0 top-0" style={{marginRight:'50%'}}></span>
              <span className="hidden lg:block absolute w-full border-black lg:border-t-1gap left-0 bottom-0" style={{marginLeft:'50%'}}></span>
            </div>
          </div>

          <div className="flex flex-col flex-grow mb-2gap justify-center xl:justify-start">
            <div>
              <h2 className="font-bold text-2xl leading-tight mb-1gap">Atelier Y+はドイツ美大受験専門のオンラインクラスです</h2>
              <p>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。</p>
            </div>
          </div>

        </div>

        <div className="flex-grow lg:pl-1gap xl:pl-1col overflow-hidden relative">
          <div className="lg:pl-1gap pg:pt-1gap pb-1gap lg:pb-2gap relative">
            <div className="bg-black border-black border-1/2gap lg:border-1gap lg:-ml-1gap">
              <img className="block w-full shadow-md max-w-5col4gap lg:max-w-full mx-auto" src="https://placekitten.com/880/620" alt="" />
            </div>
            <span className="hidden lg:block absolute w-screen border-black border-t-1gap left-0 top-0"></span>
            <span className="hidden lg:block absolute h-screen border-black border-l-1gap left-0 top-0"></span>
          </div>
        </div>

        <span className="hidden lg:block absolute w-screen border-black border-t-1gap top-0" style={{left:'50%'}}></span>

      </section>

      {/* 2nd block */}

      <div className="border-black border-t-1/2gap pt-2gap lg:hidden"></div>

      <section className="flex lg:flex-row-reverse over:-mx-1col1gap over:w-14col13gap">
        <div className="flex-grow-0 lg:w-1/2 xl:w-auto">
          <div className="w-full md:w-4col3gap lg:w-full xl:w-6col5gap over:w-7col6gap">
            <div className="pb-2gap md:mr-1gap lg:mr-0 lg:ml-1col xl:ml-1col1gap over:pr-1col1gap">

              <div className="relative hidden lg:block pt-3gap">
                <span className="absolute left-0 top-0 border-black border-t-1gap w-screen">
                  <span className="hidden lg:block absolute left-0 top-0 border-black border-3/2gap rounded-full -mt-2gap -ml-2gap"></span>
                </span>
              </div>

              <h2 className="font-bold text-3xl leading-tight mb-1gap"><Link to="/">ドイツ美大受験<br />オンラインクラス</Link></h2>
              <p className="mb-1gap">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろ</p>
              <ul className="space-y-2 mt-2 mb-3 ml-1gap leading-tight">
                <li className="list-item-triangle-black"><Link to="/">授業内容</Link></li>
                <li className="list-item-triangle-black"><Link to="/">概要</Link></li>
                <li className="list-item-triangle-black"><Link to="/">講師について</Link></li>
                <li className="list-item-triangle-black"><Link to="/">生徒さんの声</Link></li>
                <li className="list-item-triangle-black"><Link to="/">よくある質問</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="hidden md:block lg:w-1/2 xl:w-auto flex-grow">
          <div className="pb-2gap">
            <img className="block w-full border-black border-1/2gap lg:border-1gap shadow-md" src="https://placekitten.com/600/400" alt="" />
          </div>
        </div>
      </section>

      {/* 3rd block */}

      <div className="border-black border-t-1/2gap pt-2gap lg:hidden"></div>

      <section className="flex md:flex-row-reverse lg:flex-row relative over:-mx-1col1gap over:w-14col13gap">
        <div className="flex-grow-0 lg:w-1/2 xl:w-auto">
          <div className="w-full md:w-4col3gap lg:w-full xl:w-6col5gap over:w-7col6gap">
            <div className="pb-2gap md:ml-1gap lg:ml-0 lg:mr-1col xl:mr-1col1gap over:pl-1col1gap">

              <div className="relative hidden lg:block pt-3gap">
                <span className="absolute right-0 top-0 border-black border-t-1gap w-screen">
                  <span className="hidden lg:block absolute right-0 top-0 border-black border-3/2gap rounded-full -mt-2gap -mr-2gap"></span>
                </span>
              </div>

              <h2 className="font-bold text-3xl leading-tight mb-1gap"><Link to="/">その他のサービス</Link></h2>
              <p className="mb-1gap">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろ</p>
              <ul className="space-y-2 mt-2 mb-3 ml-1gap leading-tight">
                <li className="list-item-triangle-black"><Link to="/">ドイツ語習得サポート</Link></li>
                <li className="list-item-triangle-black"><Link to="/">進路相談サポート</Link></li>
                <li className="list-item-triangle-black"><Link to="/">講師について</Link></li>
                <li className="list-item-triangle-black"><Link to="/">生徒さんの声</Link></li>
                <li className="list-item-triangle-black"><Link to="/">よくある質問</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="hidden md:block lg:w-1/2 xl:w-auto flex-grow">
          <img className="block w-full border-black border-1/2gap lg:border-1gap lg:-mb-1gap" src="https://placekitten.com/600/600" alt="" />
        </div>
      </section>

      {/* 4th block */}

      <div className="border-black border-t-1/2gap pt-2gap lg:hidden"></div>

      <section>

        <div className="relative hidden lg:block pt-3gap">
          <span className="absolute left-0 top-0 border-black border-t-1gap w-screen">
            <span className="hidden lg:block absolute left-0 top-0 border-black border-3/2gap rounded-full -mt-2gap -ml-1/2gap xl:-ml-2gap"></span>
          </span>
        </div>

        <h2 className="font-bold text-3xl leading-tight mb-1gap">卒業生たちの声</h2>
        <p className="mb-2gap">Atelier Y+の卒業生たちの声です。</p>
        <ul className="grid lg:grid-cols-2 gap-1gap mb-2gap">
          <li className="flex items-center">
            <img className="block w-1col1gap h-1col1gap md:w-full md:h-auto max-w-2col1gap max-h-2col1gap rounded-full shadow-md" src="https://placekitten.com/200/200" alt="" />
            <p className="ml-1gap">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。</p>
          </li>
          <li className="flex items-center">
            <img className="block w-1col1gap h-1col1gap md:w-full md:h-auto max-w-2col1gap max-h-2col1gap rounded-full shadow-md" src="https://placekitten.com/200/200" alt="" />
            <p className="ml-1gap">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。</p>
          </li>
          <li className="flex items-center">
            <img className="block w-1col1gap h-1col1gap md:w-full md:h-auto max-w-2col1gap max-h-2col1gap rounded-full shadow-md" src="https://placekitten.com/200/200" alt="" />
            <p className="ml-1gap">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。</p>
          </li>
          <li className="flex items-center">
            <img className="block w-1col1gap h-1col1gap md:w-full md:h-auto max-w-2col1gap max-h-2col1gap rounded-full shadow-md" src="https://placekitten.com/200/200" alt="" />
            <p className="ml-1gap">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。</p>
          </li>
          <li className="flex items-center">
            <img className="block w-1col1gap h-1col1gap md:w-full md:h-auto max-w-2col1gap max-h-2col1gap rounded-full shadow-md" src="https://placekitten.com/200/200" alt="" />
            <p className="ml-1gap">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。</p>
          </li>
          <li className="flex items-center">
            <img className="block w-1col1gap h-1col1gap md:w-full md:h-auto max-w-2col1gap max-h-2col1gap rounded-full shadow-md" src="https://placekitten.com/200/200" alt="" />
            <p className="ml-1gap">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。</p>
          </li>
          <li className="flex items-center">
            <img className="block w-1col1gap h-1col1gap md:w-full md:h-auto max-w-2col1gap max-h-2col1gap rounded-full shadow-md" src="https://placekitten.com/200/200" alt="" />
            <p className="ml-1gap">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。</p>
          </li>
        </ul>
      </section>

    </Layout>
  )

};

export default Page
